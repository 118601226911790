<template>
    <MainPaperFormView :Type="'Form'" :Status="5" :canWatch="editableForm" > </MainPaperFormView>
    <!-- <Splitter layout="vertical" style="height: 100%;"> -->
    <!--     <SplitterPanel :size="30" :minSize="10" style="overflow: hidden;position: relative;"> -->
    <!--         <FormDataTable ref="dataTable" :Type="'post'" :FormStatus="5"  :Loading="Loading" /> -->
    <!--     </SplitterPanel> -->
    <!--     <SplitterPanel  :size="70" :minSize="10" style="position: relative;"> -->
    <!--         <ScrollPanel class="customScrollPanel" style="position: absolute; top: 0px; left: 0px; right: 0px; bottom: 0px;"> -->
    <!--             <div style="padding: 20px;" v&#45;if="editableForm"> -->
    <!--                 <viewPostedForm  /> -->
    <!--                 <template v&#45;if="showSpinner"> -->
    <!--                     <div class="progressSpinnerContainer"> -->
    <!--                         <ProgressSpinner  /> -->
    <!--                     </div> -->
    <!--                 </template> -->
    <!--             </div> -->
    <!--             <div v&#45;else class="p&#45;p&#45;4"> -->
    <!--                 <Nico /> -->
    <!--             </div> -->
    <!--         </ScrollPanel> -->
    <!--     </SplitterPanel> -->
    <!-- </Splitter> -->
</template>

<script>
// @ is an alias to /src

// import Splitter from 'primevue/splitter';
// import SplitterPanel from 'primevue/splitterpanel';
// import viewPostedForm from '@/components/viewPostedForm'
// import ScrollPanel from 'primevue/scrollpanel';
// import Nico from '@/components/Nico';
// import ProgressSpinner from 'primevue/progressspinner';
// import FormDataTable from '@/components/FormDataTable'
import MainPaperFormView from '@/components/MainPaperFormView';

export default {
    name: 'DeletedBox',
    components: {
        MainPaperFormView
    },
    created() {
    },
    computed: {
        editableForm: {
            get: function() {
                return this.$store.getters["gridDataStore/isEditableForm"]
            },
        }
    },
    data: function() {
        return {
            Loading: false,
        }
    },
    methods: {
    }
}
</script>
